import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
//import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers-blog'

const PageAuthors = props => {
  const authors = useBlogAuthors()

  return (
    <Layout {...props}>
      {/*<Seo title='Our Team' />*/}
      {/*<GatsbySeo
        title='Our Team'
        description='Our Team'
        openGraph={{
          title: 'Our Team',
          description: 'Our Team',
          url: 'https://www.ownahome.ng/',
          images: 
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'find out about us'
            },
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />*/}
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Team Members'
          subheader='FlexiBlog theme comes with a pre-made contact form component. You can integrate this form with serverless services such as Formspree, Getform, FormKeep and others to receive form submissions via email.'
        />
      </Stack>
      <Stack>
        <Main>
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageAuthors
